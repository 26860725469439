import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

import css from './ListingPage.module.css';

const customReviews1 = [
  createReview(
    '662c0681-e361-4812-b38e-d23c06b97e99',
    'Lilit C',
    5,
    "I ordered Rosalia's Baptism cookies for my niece's christening in April 2024, and they were absolutely perfect! The designs were beautifully detailed, and the cookies tasted delicious. We chose a mix of cross-shaped cookies and ones with personalized messages, and everyone at the party loved them. The packaging was also elegant, making them a great gift option. I highly recommend Rosalia's for any special occasion!",
    '2024-04-15T10:30:00.000Z'
  ),
  createReview(
    '662ef0ee-414e-4c93-9071-89c41170ae2e',
    'Pan U',
    5,
    "We had Rosalia make cookies for our son's baptism. The cookies looked amazing and were very professionally done. We chose a classic design with white icing and silver accents, which fit the theme perfectly. The quality of the cookies made everything so worth it.",
    '2024-05-20T14:45:00.000Z'
  ),
  createReview(
    '66240c51-448d-4be8-8d95-ffbc13eb82db',
    'Farena H',
    5,
    "Rosalia's Baptism cookies were a hit at my daughter's christening in June. The cookies were not only beautiful but also tasted incredible! We had a variety of designs, including little angel shapes and personalized name cookies, and they were a perfect addition to the celebration. The service was excellent, and she was very responsive and accommodating to our requests. I will definitely order from them again for future events!",
    '2024-06-20T14:45:00.000Z'
  ),
];

const customReviews2 = [
  createReview(
    '66218d40-198a-4c4a-8355-09e3ec856c2b',
    'Molly P',
    5,
    "For my sister's baby shower in June, we ordered custom cookies from Rosalia, and they were absolutely adorable! The theme was \"little prince,\" and Rosalia's team nailed it with cute blue and gold cookies shaped like crowns, baby onesies, and stars. The guests couldn't stop complimenting them. Shipping arrived right on time before my delivery date I selected.",
    '2024-06-15T10:30:00.000Z'
  ),
  createReview(
    '661ff938-3cca-4adc-8f46-09ddeb600abc',
    'Jessica T',
    5,
    'I ordered custom cookies from Rosalia for my wedding, and they were stunning! We chose a romantic theme with heart-shaped cookies, some decorated with our initials and others with delicate floral patterns. The cookies were not only a visual treat but also delicious, with just the right amount of sweetness. They were a perfect addition to our dessert table and made lovely favors for our guests. Rosalia was fantastic to work with, very accommodating to our specific design requests, and the cookies were shipped right on time. Highly recommended for any special occasion!',
    '2024-05-20T14:45:00.000Z'
  ),
];

function createReview(uuid, name, rating, content, date) {
  return {
    id: {
      _sdkType: 'UUID',
      uuid: new UUID(uuid),
    },
    type: 'review',
    attributes: {
      type: 'ofProvider',
      state: 'public',
      deleted: false,
      createdAt: date,
      rating: rating,
      content: content,
    },
    author: {
      attributes: {
        profile: {
          abbreviatedName: name.split(' ')[0][0] + name.split(' ')[1][0],
          displayName: name,
          bio: null,
          publicData: {},
          metadata: {},
        },
        banned: false,
        deleted: false,
        createdAt: date,
      },
      id: {
        _sdkType: 'UUID',
        uuid: uuid,
      },
      type: 'user',
      profileImage: null,
    },
  };
}

const SectionReviews = props => {
  const { reviews, fetchReviewsError, listingId } = props;
  const uuidsToRemove = [
    '673cde45-ccb3-49fa-823b-c6a358775c29',
    '673cf9e2-df88-437a-894d-088da91a777c',
    '66f09382-9b55-4dc8-9e46-53f1a36667d9',
    '67339317-e167-46a0-8195-1485932cb508',
  ];
  const filteredreviews = reviews.filter(review => !uuidsToRemove.includes(review.id.uuid));

  let updatedReviews =
    listingId.uuid === '66870f06-3019-403b-8957-ed985f4b1fee'
      ? [...filteredreviews, ...customReviews1]
      : listingId.uuid === '669553e4-5747-4530-a58e-4bdb8955950e'
      ? [...filteredreviews, ...customReviews2]
      : filteredreviews;

  return (
    <div className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: updatedReviews.length }} />
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={updatedReviews} />
    </div>
  );
};

export default SectionReviews;
